import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import { ReUseHOneTag } from './ReUseHOneTag'
import { ReUsePtag } from './ReUsePtag'
import styled from 'styled-components'
import { Link } from 'react-scroll'

const BannerWapper = styled.div`
  h2 {
    /* color: rgb(15, 33, 55); */
    color: #047cc2;
  }
  p {
    /* color: rgba(52, 61, 72, 0.8); */
    color: #071741;
  }
  ul {
    /* color: rgba(52, 61, 72, 0.8); */
    color: #071741;
    font-size: 1.2rem;
  }
`

export const Banner = (props: any) => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    // Percentage of item in view to trigger animation
    threshold: 0.25,
  })

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <BannerWapper>
      <motion.div
        className={props.containSection}
        ref={ref}
        animate={controls}
        initial='hidden'
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 25 },
        }}
        transition={{ ease: 'easeOut', duration: 1.25, delay: 0.35 }}>
        <div className={props.BannerSectionOne}>
          <ReUseHOneTag HeadingStyle={props.BannerHeadingSize} Heading={props.BannerHeading} />
          <ReUsePtag para={props.paraOne} paraStyle={props.BannerPara} />
          <ReUsePtag para={props.paraTwo} paraStyle={props.BannerPara} />
          <ReUsePtag para={props.paraThree} paraStyle={props.BannerPara} />
          <ul className={props.UlStyle} style={{ wordBreak: 'break-all' }}>
            {props.scrollPathOne && (
              <Link
                to={props.scrollPathOne}
                smooth={true}
                duration={500}
                offset={props.offsetValue}
                className={props.LiStyle}>
                <span className={props.IconOneStyle}>{props.Icon}</span>
                {props.LiIttemOne}
              </Link>
            )}
            {props.scrollPathTwo && (
              <Link
                to={props.scrollPathTwo}
                smooth={true}
                duration={500}
                className={props.LiStyle}
                offset={props.offsetValue}>
                <span className={props.IconTwoStyle}>{props.Icon}</span>
                {props.LiIttemTwo}
              </Link>
            )}
            {props.scrollPathThree && (
              <Link
                to={props.scrollPathThree}
                smooth={true}
                duration={500}
                className={props.LiStyle}
                offset={props.offsetValue}>
                <span className={props.IconThreeStyle}>{props.Icon}</span>
                {props.LiIttemThree}
              </Link>
            )}
            {props.scrollPathFour && (
              <Link
                to={props.scrollPathFour}
                smooth={true}
                duration={500}
                className={props.LiStyle}
                offset={props.offsetValue}>
                <span className={props.IconFourStyle}>{props.IconLast}</span>
                {props.LiIttemFour}
              </Link>
            )}
            {props.scrollPathFour && (
              <Link
                to={props.scrollPathFive}
                smooth={true}
                duration={500}
                className={props.LiStyle}
                offset={props.offsetValue}>
                <span className={props.IconFiveStyle}>{props.IconLast}</span>
                {props.LiIttemFive}
              </Link>
            )}
          </ul>
        </div>
        <div className={props.BannerSectionTwo}>
          <img src={props.ImagePath} alt={props.altName} className={props.BannerImage} />
        </div>
      </motion.div>
    </BannerWapper>
  )
}
