import React from 'react'
import styled from 'styled-components'
// import { ReUsePtag } from './ReUsePtag'
import { ReuseImgTag } from './ReuseImgTag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import { LinkHubspot } from './LinkHubspot'
import ResourcesCardWebinarsData from '../Data/ResourcesCardWebinarsData.json'
// import { Button } from './Button'
import { ResourcesPageButton } from './ResourcesPageButton'

const AppTabpanelResourcesWebinarsCardFrontWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .MainResourcesCardWebinarsSection {
    padding: 0px 30px 20px 30px;
    @media (max-width: 1090px) {
      padding: 0px 15px 2rem 15px;
    }
  }
  .ResourcesCardWebinarsSection {
    display: flex;
    max-width: 1590px;
    justify-content: space-evenly;
    margin: auto;

    @media (max-width: 7680px) {
      max-width: 1590px;
      flex-wrap: wrap;
    }
  }
  .ResourcesWebinarsCardSection {
    width: 400px;
    height: auto;
    box-shadow: 2px 4px 10px 0 rgb(0 0 0 / 10%);
    border: 1px solid #34a1d5;
    margin: 20px 0px 20px 0px;
    border-radius: 12px;
  }
  .ResourcesWebinarsCardSection:hover {
    box-shadow: rgb(160 161 163 / 88%) 0px 3px 7px 0px;
    border: 2px solid #34a1d5;
  }

  .ReourcesCardDatePara {
    font-size: 1rem;
    padding: 0px 10px 0px 10px;
    margin-bottom: 0;
  }
  .ReourcesCardWebinarsHeading {
    font-size: 21px;
    padding: 0px 10px 0px 10px;
    margin-bottom: 0;
    color: #1f1f1f;
  }
  .ReourcesCardPara {
    font-size: 1rem;
    padding: 0px 10px 0px 10px;
  }
  .ReourcesCardWebinarsImage {
    width: 100%;
    height: 280px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
  .ButtonSectionWebinarsReourcesCard {
    /* margin-top: 20px; */
    margin-bottom: 35px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .ReourcesCardButton:hover {
    text-decoration: dotted;
    color: #194a8c;
  }
  .CardLinkHub {
    text-decoration: none;
  }
  .achorPara {
    font-size: 1rem;
    font-weight: bold;
    color: #34a1d5;
    padding-left: 6px;
    padding-right: 6px;
  }
  .CardReourcesWebinarsContentSection {
    display: flex;
    /* height: 46%; */
    flex-direction: column;
    height: 42%;
    justify-content: space-between;
    /* margin-top: 30px; */
    /* margin-top: 30px; */
  }
`

export const AppTabpanelResourcesWebinarsCardFront = () => {
  return (
    <AppTabpanelResourcesWebinarsCardFrontWapper>
      <div className="MainResourcesCardWebinarsSection">
        <div className="ResourcesCardWebinarsSection">
          {ResourcesCardWebinarsData.map((data, index) => {
            return (
              <div className="ResourcesWebinarsCardSection" key={index}>
                <div className="">
                  <ReuseImgTag ImageStyle="ReourcesCardWebinarsImage" ImagePath={data.ImagePath} AltName="" />
                </div>
                <div className="CardReourcesWebinarsContentSection">
                  <ReUseHTwotag Heading={data.title} HeadingStyle="ReourcesCardWebinarsHeading" />
                  {/* <ReUsePtag para={data.DatePara} paraStyle="ReourcesCardDatePara" /> */}

                  <div className="ButtonSectionWebinarsReourcesCard">
                    <LinkHubspot to={data.InternalPageShiftUrl} className="CardLinkHub">
                      <span className="sr-only">Jump to packages</span>
                      <ResourcesPageButton
                        cta={data.eventButtonName}
                        label="Read More"
                        type="submit"
                        className="demobutton"
                      />
                    </LinkHubspot>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {/* <img className="wave-background" src={SignUpData.ImagePath} /> */}
    </AppTabpanelResourcesWebinarsCardFrontWapper>
  )
}
