import React from 'react'
import styled from 'styled-components'
import { Banner } from './Banner'
import ResourcesBannerData from '../Data/ResourcesBannerData.json'

const AppResourcesBannerWapper = styled.div`
  .containSection {
    display: flex;
    max-width: 1920px;
    margin: auto;
    padding: 0px 0px 0px 0px;
    justify-content: space-between;
    overflow: hidden;
    @media (max-width: 1390px) {
      /* padding: 80px 30px; */
    }
    @media (max-width: 1090px) {
      /* padding: 80px 15px;
      justify-content: center;
      flex-wrap: wrap; */
    }
  }
  .BannerResourcesSectionOne {
    background-image: url('https://storage.googleapis.com/raindroppublic/website_data/Resources-page-banner.png');
    /* margin-top: 3rem; */
    height: 35vh;
    width: 100vw;
    @media (max-width: 1390px) {
      /* margin-top: 330px; */
    }
  }
  .ResourcesBannerSectionTwo {
    height: auto;
    width: 50%;
    @media (max-width: 1390px) {
      height: auto;
      width: auto;
    }
  }
  .BannerResourcesHeadingSize {
    padding: 0px 100px 0px 100px;
    margin-top: 155px;
    color: #fff;
    max-width: 100%;
    text-align: center;
    /* padding-bottom: 3%; */
    font-size: 2.4rem;
    line-height: 1.30435em;
    @media (max-width: 1390px) {
      margin-top: 130px;
      /* max-width: 1100px; */
      /* margin-top: 3rem; */
    }
    @media (max-width: 1090px) {
      max-width: unset;
      margin-top: 150px;
      font-size: 2.4rem;
    }
    @media (max-width: 760px) {
      max-width: unset;
      margin-top: 100px;
      font-size: 1.4rem;
    }
    @media (min-width: 1920px) {
      padding: 0px 300px 0px 300px;
    }
  }
  .BannerResourcesPara {
    display: none;
  }
  .BannerResourcesImage {
    width: 100%;
    height: auto;
  }
  .BannerResourcesLiStyle {
    text-decoration: none;
    list-style-type: none;
    padding: 1%;
    word-break: unset;
  }
  .iconColor {
    display: none;
  }
  .BannerResourcesUlStyle {
    display: grid;
    margin: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`

export const AppResourcesBanner = () => {
  return (
    <AppResourcesBannerWapper>
      {ResourcesBannerData.map((data, index) => {
        return (
          <div key={index}>
            <Banner
              containSection="containSection"
              BannerHeading={data.Heading}
              // ImagePath={data.ImagePath}
              BannerSectionOne="BannerResourcesSectionOne"
              // BannerSectionTwo='ResourcesBannerSectionTwo'
              BannerHeadingSize="BannerResourcesHeadingSize"
              BannerPara="BannerResourcesPara"
              // BannerImage='BannerResourcesImage'
              // altName='AboutBannerImage'
              UlStyle="BannerResourcesUlStyle"
              LiStyle="BannerResourcesLiStyle"
              IconStyle="iconColor"
              IconThreetyle="iconColor"
            />
          </div>
        )
      })}
    </AppResourcesBannerWapper>
  )
}
