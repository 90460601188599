import React from 'react'
import styled from 'styled-components'

export const ResourcesPageButtonWrapper = styled.button`
  position: relative;
  display: inline-block;
  padding: 0 16px;
  text-align: center;
  font-family: 'GothamHTF', 'Helvetica Neue', sans-serif;
  color: #34a1d5;
  letter-spacing: 0.1rem;
  text-transform: capitalize;
  text-decoration: none;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #34a1d5;
  -moz-transition: background 0.3s linear;
  /* WebKit */
  -webkit-transition: background 0.3s linear;
  /* Opera */
  -o-transition: background 0.3s linear;
  /* Standard */
  transition: background 0.3s linear;
  background-size: 400%;

  /* border: none; */
  z-index: 1;
  width: fit-content;
  align-self: center;
  /* @media (max-width: 650px) {
    width: 260px;
  } */

  &:focus {
    outline: none;
  }
  &:hover {
    background: #004991;
    color: #fff;
    /* Firefox */
    -moz-transition: background 0.3s ease-in;
    /* WebKit */
    -webkit-transition: background 0.3s ease-in;
    /* Opera */
    -o-transition: background 0.3s ease-in;
    /* Standard */
    transition: background 0.3s ease-in;
    cursor: pointer;
    animation: animate 8s linear infinite;

    &::before {
      filter: blur(20px);
      opacity: 1;
      animation: animate 8s linear;
    }
  }

  &::before {
    content: '';
    position: absolute;

    z-index: -1;
    background-size: 400%;
    border-radius: 40px;
    opacity: 0;
    transition: 0.5s;
  }
  @keyframes animate {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 400%;
    }
  }
  .buttontextandicon {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    min-width: 120px;
  }
  .logoicon {
    width: 28px;
    height: 28px;
    margin-right: 6px;
  }
  .buttontext {
    background-color: transparent;
    flex-wrap: nowrap;
    align-items: center;
    font-size: 22px;
    color: white;
    margin-top: 10px;
    margin-bottom: 10px;
    @media (max-width: 650px) {
      margin: auto;
    }
  }
`

export const ResourcesPageButton = (props: any) => {
  return (
    <ResourcesPageButtonWrapper
      aria-label={props.label}
      type={props.type}
      onClick={props.onClick}
      style={{ height: 50, borderRadius: 25, fontSize: 22 }}
    >
      {props.cta}
    </ResourcesPageButtonWrapper>
  )
}

export const ResourcesPageButtonCompact = (props: any) => {
  return (
    <ResourcesPageButtonWrapper
      aria-label={props.label}
      type={props.type}
      onClick={props.onClick}
      style={{ height: 50, borderRadius: 25, fontSize: 22 }}
    >
      {props.cta}
    </ResourcesPageButtonWrapper>
  )
}

export const ResourcesPageButtonCompactWithLogo = (props: any) => {
  return (
    <ResourcesPageButtonWrapper
      aria-label={props.label}
      type={props.type}
      onClick={props.onClick}
      style={{ height: 50, borderRadius: 25, fontSize: 19 }}
    >
      <div className="buttontextandicon">
        {/* <img src='https://storage.googleapis.com/raindroppublic/website_data/raindrop_logo_white.svg' className='logoicon' /> */}
        <p className="buttontext">{props.cta}</p>
      </div>
    </ResourcesPageButtonWrapper>
  )
}

export const ResourcesPageButtonGoogle = (props: any) => {
  return (
    <ResourcesPageButtonWrapper
      aria-label={props.label}
      type={props.type}
      disabled={props.disabled}
      onClick={props.onClick}
      style={{
        height: 50,
        borderRadius: 25,
        fontSize: 22,
        background: props.disabled ? '#e1f4ff' : 'white',
        cursor: props.disabled ? 'none' : 'pointer',
        color: '#004991',
        border: '1px solid #004991',
      }}
    >
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <div style={{ marginRight: '10px', background: 'rgb(255, 255, 255)', borderRadius: '2px', marginTop: 4 }}>
          <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
            <g fill="#000" fillRule="evenodd">
              <path
                d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
                fill="#EA4335"
              ></path>
              <path
                d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
                fill="#4285F4"
              ></path>
              <path
                d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
                fill="#FBBC05"
              ></path>
              <path
                d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
                fill="#34A853"
              ></path>
              <path fill="none" d="M0 0h18v18H0z"></path>
            </g>
          </svg>
        </div>
        {props.cta}
      </div>
    </ResourcesPageButtonWrapper>
  )
}
